.tim-row {
  margin-bottom: 20px;
}

.tim-white-buttons {
  background-color: #777777;
}

.typography-line {
  padding-left: 25%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}

.typography-line span {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  position: absolute;
  width: 260px;
  text-transform: none;
}

.tim-row {
  padding-top: 60px;
}

.tim-row h3 {
  margin-top: 0;
}

.offline-doc .page-header {
  display: flex;
  align-items: center;
}

.offline-doc .footer {
  position: absolute;
  width: 100%;
  background: transparent;
  bottom: 0;
  color: #fff;
  z-index: 1;
  padding-left: 0;
}

@media all and (min-width: 992px) {
  .sidebar .nav>li.active-pro {
    position: absolute;
    width: 100%;
    bottom: 10px;
  }
}

.card.card-upgrade .card-category {
  max-width: 530px;
  margin: 0 auto;
}

.offline-doc .page-header {
  min-height: 100vh;
  max-height: 999px;
  padding: 0;
  color: #ffffff;
  position: relative; }
  .offline-doc .page-header .page-header-image {
    position: absolute;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    z-index: -1; }
  .offline-doc .page-header .content-center {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    color: #FFFFFF;
    padding: 0 15px;
    width: 100%;
    max-width: 880px; }
  .offline-doc .page-header footer {
    position: absolute;
    bottom: 0;
    width: 100%; }
  .offline-doc .page-header .container {
    height: 100%;
    z-index: 1; }
  .offline-doc .page-header .category,
  .offline-doc .page-header .description {
    color: rgba(255, 255, 255, 0.8); }
  .offline-doc .page-header.page-header-small {
    min-height: 60vh;
    max-height: 440px; }
  .offline-doc .page-header.page-header-mini {
    min-height: 40vh;
    max-height: 340px; }
  .offline-doc .page-header .title {
    margin-bottom: 15px; }
  .offline-doc .page-header .title + h4 {
    margin-top: 10px; }
  .offline-doc .page-header:after, .offline-doc .page-header:before {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: ""; }
  .offline-doc .page-header:before {
    background-color: rgba(0, 0, 0, 0.8); }

.text-primary-2 {
  color: #8a337b !important; }

a.text-primary-2:hover, a.text-primary-2:focus {
  color: #831873 !important; }

.text-secondary-2 {
  color: #858586 !important; }

a.text-secondary-2:hover, a.text-secondary-2:focus {
  color: #74767a !important; }

.text-success-2 {
  color: #018066 !important; }

a.text-success-2:hover, a.text-success-2:focus {
  color: #01a181 !important; }

.text-info-2 {
  color: #124d88 !important; }

a.text-info-2:hover, a.text-info-2:focus {
  color: #03417e !important; }

.text-warning-2 {
  color: #8b4d3f !important; }

a.text-warning-2:hover, a.text-warning-2:focus {
  color: #833521 !important; }

.text-danger-2 {
  color: #88324f !important; }

a.text-danger-2:hover, a.text-danger-2:focus {
  color: #8a1a3f !important; }

.text-light-2 {
  color: #949aa1 !important; }

a.text-light-2:hover, a.text-light-2:focus {
  color: #828b94 !important; }

.text-dark-2 {
  color: #697683 !important; }

a.text-dark-2:hover, a.text-dark-2:focus {
  color: #6e848f !important; }

.text-default-2 {
  color: #3e538a !important; }

a.text-default-2:hover, a.text-default-2:focus {
  color: #243152 !important; }

.text-white-2 {
  color: #000000 !important; }

a.text-white-2:hover, a.text-white-2:focus {
  color: #7e7e7e !important; }

.text-neutral-2 {
  color: #000000 !important; }

a.text-neutral-2:hover, a.text-neutral-2:focus {
  color: #969696 !important; }

.text-darker-2 {
  color: black !important; }

a.text-darker-2:hover, a.text-darker-2:focus {
  color: black !important; }

.manage-modal {
  min-width: 50%;
}

.edit-modal {
  min-width: 700px;
}

.installer-modal {
  max-width: 750px;
}

.payment-modal {
  max-width: 750px;
}

.button-icon-detail {
  text-align: center;
  padding-top: 10px;
  border-radius: .1875rem;
  margin: 20px;
  height: 65px;
  width: 100px;
}

.button-icon-detail i {
  color: #FFFFFF;
  font-size: 1.5em;
}

.button-icon-detail p {
  color: #e44cc4 !important;
  margin-top: 0px;
  padding: 0 10px;
  font-size: .7142em;
}

.button-icon-detail-normal {
  border: 1px solid #e44cc4;
}

.button-icon-detail-selected {
  border: 3px solid #e44cc4;
}

.drive-icon-detail-normal {
  border: 1px solid #00f2c3;
}

.drive-icon-detail-normal p {
  color: #00f2c3 !important;
}

.drive-icon-detail-selected {
  border: 3px solid #00f2c3;
}

.drive-icon-detail-selected p {
  color: #00f2c3 !important;
}

input,
.StripeElement {
  border-color: #2b3553;
  border-radius: 0.4285rem;
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

input:focus,
.StripeElement--focus {
  background-color: transparent;
  border-color: #e14eca;
}